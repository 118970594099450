import React, { useEffect } from "react"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
const dark = "#1c8460"
const primary = "#26a27b"

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: dark,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: dark,
    },
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        borderColor: primary,
      },
      "&.Mui-focused fieldset": {
        borderColor: primary,
      },
    },
  },
})(TextField)

const useStyles = makeStyles((theme) => ({
  ctr: {
    paddingTop: "8rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    background: `linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(247, 249, 250, 1) 15%)`,
  },
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    backgroundColor: "transparent",
    maxWidth: "50%",
    [theme.breakpoints.only("xl")]: {
      maxWidth: "30%",
    },
    [theme.breakpoints.only("lg")]: {
      maxWidth: "40%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  h6: {
    fontWeight: 500,
    fontSize: "2.2375rem",
  },
  margin: {
    marginBottom: 8,
    marginTop: 19,
    width: "100%",
  },
  btn: {
    marginTop: "1.5625rem",
    fontSize: "1rem",
    marginLeft: 8,
    width: "6.625rem",
    marginBottom: "6.625rem",
    backgroundColor: primary,
    transition: ".5s",
    "&:hover": {
      backgroundColor: dark,
      transform: "translateY(-5px)",
    },
  },
  textField: {
    "& .MuiFormLabel-root.Mui-focused": {
      color: dark,
    },
    "& .Mui-focused fieldset": {
      borderColor: primary,
    },
    "&:hover fieldset": {
      borderColor: primary,
    },
    "& .MuiOutlinedInput-root.Muifocused .MuiOutlinedInput-notchedOutline": {
      borderColor: primary,
    },
  },
  body: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 600,
    padding: "1rem 0",
  },
}))
export default function ContactForm() {
  const classes = useStyles()

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    // gsap.set(["#textfade", ".MuiFormControl-root", ".MuiButtonBase-root"], {
    gsap.set(["#textfade", ".MuiFormControl-root", "#btnfade"], {
      opacity: 0,
      y: 100,
      overwrite: true,
    })

    ScrollTrigger.batch(
      // ["#textfade", ".MuiFormControl-root", ".MuiButtonBase-root"],
      ["#textfade", ".MuiFormControl-root", "#btnfade"],
      {
        interval: 0.1,
        batchMax: 3,
        onEnter: (batch) =>
          gsap.to(batch, {
            opacity: 1,
            y: 0,
            stagger: { each: 0.15, grid: [1, 3] },
            overwrite: true,
          }),
      }
    )
  }, [])

  return (
    <Grid container justify="center" className={classes.ctr}>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          align="center"
          gutterBottom
          className={classes.h6}
          id="textfade"
        >
          Have a project to discuss?
        </Typography>
        <Typography
          variant="body1"
          paragraph
          align="center"
          className={classes.body}
          id="textfade"
        >
          Want a quote? Lost in the web design process? Contact us for free
          guidance - even if you don't choose us, we'll give you helpful info so
          you don't get taken for a ride by the big guys.
        </Typography>
      </Grid>
      <Paper className={classes.root} elevation={0}>
        <form
          name="Portfolio Form"
          method="POST"
          // netlify
          data-netlify="true"
          style={{ width: "90%", marginLeft: "auto", marginRight: "auto" }}
        >
          <input type="hidden" name="form-name" value="Portfolio Form" />
          <CssTextField
            variant="outlined"
            className={classes.textField}
            required
            id="Full Name"
            name="Full Name"
            label="Full Name"
            placeholder="Full Name"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            variant="outlined"
            className={classes.textField}
            required
            id="Email Address"
            name="Email Address"
            label="Email Address"
            style={{
              marginBottom: 8,
              marginTop: 19,
            }}
            placeholder="Email Address"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <CssTextField
            variant="outlined"
            className={classes.textField}
            required
            id="Phone Number"
            name="Phone Number"
            label="Phone Number"
            style={{
              marginBottom: 8,
              marginTop: 19,
            }}
            placeholder="Phone Number"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
          />

          <CssTextField
            variant="outlined"
            className={classes.textField}
            required
            id="Message"
            name="Message"
            label="Message"
            style={{
              marginBottom: 8,
              marginTop: 19,
            }}
            placeholder="Message"
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            multiline
            rows={4}
          />
          <Button
            variant="contained"
            color="secondary"
            className={classes.btn}
            type="submit"
            id="btnfade"
          >
            send
          </Button>
        </form>
      </Paper>
    </Grid>
  )
}
