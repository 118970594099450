import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { StaticImage } from "gatsby-plugin-image"
import { useTheme } from "@material-ui/core/styles"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const primary = "#26a27b"

const Technology = styled.section`
  max-width: 90rem;
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
`
const settings = {
  infinite: true,
  autoplay: true,
  speed: 7000,
  autoplaySpeed: 1,
  cssEase: "linear",
  variableWidth: true,
  swipe: false,
  pauseOnHover: false,
}

const Technologies = ({ header = true }) => {
  const theme = useTheme()
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))

  return (
    <Technology className="mb-5">
      {header && (
        <h6 className="text-center mb-5">
          We utilize the <strong style={{ color: `${primary}` }}>latest</strong>{" "}
          and most
          <strong style={{ color: `${primary}` }}>
            {" "}
            technologically advanced
          </strong>{" "}
          systems and frameworks
        </h6>
      )}
      <div className="pt-2">
        <Slider {...settings}>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/graphql-icon.png"
              alt="GraphQL Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/gatsby-icon.png"
              alt="Gatsby Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/react-icon.svg.png"
              alt="React.js Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/contentful-icon.png"
              alt="Contentful Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/strapi-icon.svg"
              alt="Strapi Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/netlify-icon.png"
              alt="netlify logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/material-ui-icon.png"
              alt="Material UI Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/bootstrap-icon.png"
              alt="Bootstrap Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
          <div style={{ width: matchesXS ? 120 : 225 }}>
            <StaticImage
              src="../assets/images/nodejs-icon.png"
              alt="Node.js Logo"
              placeholder="tracedSVG"
              layout="constrained"
              objectFit="contain"
              width={50}
              height={50}
              transformOptions={{ grayscale: false }}
            />
          </div>
        </Slider>
      </div>
    </Technology>
  )
}

export default Technologies
