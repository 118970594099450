import React, { useEffect } from "react"
import { navigate } from "@reach/router"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import Grow from "@material-ui/core/Grow"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import useMediaQuery from "@material-ui/core/useMediaQuery"

import TechnologiesSlider from "./technologies"
import ContactForm from "./portfolioContactForm"

const dark = "#1c8460"
const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  h1: {
    fontSize: "3.5rem",
    letterSpacing: ".05rem",
    fontWeight: 500,
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2.75rem",
    },
  },
  h2: {
    paddingTop: "1rem",
    paddingBottom: "2rem",
    paddingLeft: "10%",
    paddingRight: "10%",
    fontSize: "1.25rem",
    fontWeight: 400,
    lineHeight: "2.25rem",
    color: "#FFFFFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.1rem",
      lineHeight: "1.75rem",
    },
  },
  h3: {
    fontWeight: 600,
    fontSize: "2.5rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  h4: {
    fontSize: "1.25rem",
    color: "#718096",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.1rem",
    },
  },
  h4b: {
    marginLeft: "auto",
    marginRight: "auto",
    width: 560,
    paddingBottom: ".25em",
    fontSize: "1rem",
    color: "#718096",
    lineHeight: "2rem",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.5rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
      width: 339,
    },
  },
  h5: {
    marginTop: "-.6em",
  },
  span: {
    color: primary,
    fontFamily: "poppins, roboto",
    fontWeight: 700,
  },
  headerCtr: {
    backgroundColor: dark,
    borderRadius: "0 0 100% 0",
    [theme.breakpoints.down("xs")]: {
      borderRadius: "0 0 81% 0",
    },
  },
  headerSection: {
    padding: "6rem 4rem",
    [theme.breakpoints.down("md")]: {
      padding: "3rem 0rem",
    },
  },
  logo: {
    marginLeft: "auto",
    marginRight: "auto",
  },
  contactBtn: {
    textTransform: "capitalize",
    backgroundColor: "#ffff",
    fontSize: "1.15rem",
    width: "60%",
    padding: ".5rem 0",
    transition: ".4s",
    "&:hover": {
      backgroundColor: primary,
      color: "#FFF",
      "& $arrowIco": {
        transform: "translate(1em)",
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "70%",
    },
  },
  arrowIco: {
    marginLeft: 10,
    transition: "0.4s",
  },
  sectionCtr: {
    padding: "2rem 0",
    [theme.breakpoints.down("xs")]: {
      paddingTop: "4rem",
      paddingBottom: 0,
    },
  },
  "@keyframes scrollProject": {
    "100%": {
      transform: "translateY(calc(-100% + 420px))",
    },
  },
  "@keyframes scrollFeaturedProject": {
    "100%": {
      transform: "translateY(calc(-100% + 600px))",
    },
  },
  folioInfoWrapper: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    padding: "2rem 1rem",
    background: primary,
    color: "#FFFF",
    borderRadius: "16px 16px 0 0",
    transform: "translateY(0%) scale(1.0)",
    transition: "1s",
    opacity: 0,
    zIndex: -1,
    pointerEvents: "none",
    marginTop: "-.1em",
  },
  columnWrapper: {
    "&:hover": {
      cursor: "pointer",
      "& $folioInfoWrapper": {
        transitionDelay: ".5s",
        transform: "translateY(-5%) scale(1.05)",
        opacity: 1,
        zIndex: 1,
      },
    },
  },
  projectImg: {
    position: "relative",
    boxShadow:
      "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    borderRadius: 15,
    transition: "1s",
    "& img": {
      objectPosition: "top center",
      borderRadius: 15,
    },
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow:
        "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    },
  },
  outerImg: {
    height: 420,
    "&:hover": {
      "& img": {
        height: "auto",
        animation: `$scrollProject 11s ease-in-out; animation-fill-mode: forwards`,
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 350,
    },
  },
  innerImg: {
    height: 600,
    "&:hover": {
      "& img": {
        height: "auto",
        animation: `$scrollFeaturedProject 5s ease-in-out; animation-fill-mode: forwards`,
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxHeight: 350,
    },
  },
  secondRow: {
    marginTop: "2rem",
  },
  jdemoblog: {
    marginTop: "1.3rem",
    "& img": {
      height: "100%",
    },
  },
}))

export default function PortfolioMainComponent() {
  const theme = useTheme()
  const classes = useStyles()
  const [isMounted, setIsMounted] = React.useState(false)

  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const matchesMD = useMediaQuery(theme.breakpoints.down("md"))

  const handleClick = (e) => {
    e.preventDefault()
    switch (e.currentTarget.id) {
      case "honeybee":
        window.open("https://snappytheme.shop/", "_blank")
        break
      case "andreattawaterscapes":
        navigate("/portfolio/andreatta-waterscapes/")
        // window.open("https://andreattawaterscapes.netlify.app/", "_blank")
        break
      case "onesnappy":
        window.open("https://onesnappy.netlify.app/", "_blank")
        break
      case "steno":
        window.open("https://snappysteno.netlify.app/", "_blank")
        break
      case "jsblog":
        window.open("https://jdemoblog.netlify.app/", "_blank")
        break
      case "nextup":
        break
      default:
        break
    }
  }

  useEffect(() => {
    let isCancelled = false
    gsap.registerPlugin(ScrollTrigger)
    if (!isCancelled) {
      setIsMounted(true)
    }
    gsap.set("#projectImage", { opacity: 0, y: 100, overwrite: true })

    ScrollTrigger.batch("#projectImage", {
      interval: 0.1, // time window (in seconds) for batching to occur.
      batchMax: 3, // maximum batch size (targets). Can be function-based for dynamic values

      onEnter: (batch) =>
        gsap.to(batch, {
          opacity: 1,
          y: 0,
          stagger: { each: 0.15, grid: [1, 3] },
          overwrite: true,
        }),
      onLeave: (batch) =>
        gsap.set(batch, { opacity: 0.5, y: -100, overwrite: true }),
      onEnterBack: (batch) =>
        gsap.to(batch, { opacity: 1, y: 0, stagger: 0.15, overwrite: true }),
      onLeaveBack: (batch) =>
        gsap.set(batch, { opacity: 0.5, y: 0, overwrite: true }),
      start: "top bottom",
      end: "top -460px",
    })
    return () => {
      isCancelled = true
    }
  }, [])

  return (
    <>
      <Grid container className={classes.headerCtr}>
        <Container
          maxWidth={matchesXS ? undefined : "md"}
          className={classes.headerSection}
        >
          <Grow
            in={isMounted}
            style={{ transformOrigin: "50% 20rem" }}
            timeout={1000}
          >
            <Grid item xs={12}>
              <Typography variant="h1" align="center" className={classes.h1}>
                Web Design Portfolio
              </Typography>
              <Typography variant="h2" className={classes.h2} align="center">
                We make web design & development easy for small businesses.
                <br />
                Headache free. Blazing fast. Mobile friendly.
              </Typography>
            </Grid>
          </Grow>
          <Grow
            in={isMounted}
            style={{ transformOrigin: "50% 20rem" }}
            timeout={1000}
          >
            <Grid item container justify="center" xs={12}>
              <Button
                variant="contained"
                className={classes.contactBtn}
                component={Link}
                to="/contact/"
                elevation={24}
              >
                Get in touch{" "}
                <ArrowForwardIosIcon
                  fontSize="small"
                  className={classes.arrowIco}
                />
              </Button>
            </Grid>
          </Grow>
        </Container>
      </Grid>
      <Grid
        container
        justify="center"
        direction="row"
        className={classes.sectionCtr}
      >
        <Grid item xs={12}>
          <Grow
            in={isMounted}
            style={{ transformOrigin: "50% 20rem" }}
            timeout={1000}
          >
            <Container maxWidth={matchesXS ? undefined : "sm"}>
              <Typography
                variant="h3"
                align="center"
                className={classes.h3}
                gutterBottom
              >
                <span className={classes.span}>Snappy</span> websites
              </Typography>
              <Typography variant="h4" align="center" className={classes.h4b}>
                40% of all people abandon a website that takes more than 3
                seconds to load.
              </Typography>
              <Typography variant="h4" align="center" className={classes.h4}>
                We write every line of code by hand & package it with the latest
                technology to provide unmatched speed.
              </Typography>
            </Container>
          </Grow>
          <Grow
            in={isMounted}
            timeout={1000}
            style={{ transformOrigin: "50% 20rem" }}
          >
            <Container maxWidth={matchesXS ? undefined : "md"}>
              <TechnologiesSlider header={false} grayscale={true} />
            </Container>
          </Grow>
        </Grid>
      </Grid>
      <Grid container justify="center" direction="row">
        <Grid
          item
          container
          xs={12}
          spacing={6}
          style={{ padding: matchesMD ? "0 .5rem" : "0 6rem" }}
        >
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ height: 400, position: "relative" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  onClick={handleClick}
                  src="../assets/images/honeybe.jpg"
                  alt="Honeybee Site"
                  placeholder="tracedSVG"
                  id="honeybee"
                  className={`${classes.projectImg} ${classes.outerImg}`}
                />
                <div className={classes.folioInfoWrapper}>
                  <div>
                    <Typography variant="h5" className={classes.h5}>
                      HoneyBee
                    </Typography>
                    <Typography variant="body1" className={classes.bodyTxt}>
                      HoneyBee is a business site made by Snappy Web Design.
                      Click the image to view the page
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ height: 400, position: "relative" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  src="../assets/images/steno.jpg"
                  alt="Snappy Steno"
                  id="steno"
                  onClick={handleClick}
                  placeholder="tracedSVG"
                  className={`${classes.projectImg} ${classes.outerImg} ${classes.secondRow}`}
                />
                <div
                  className={classes.folioInfoWrapper}
                  style={{ marginTop: "2em" }}
                >
                  <div>
                    <Typography variant="h5" className={classes.h5}>
                      Steno
                    </Typography>
                    <Typography variant="body1" className={classes.bodyTxt}>
                      Steno is a blog made by Snappy Web Design. Click the image
                      to view the page
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ position: "relative" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  src="../assets/images/andreattawaterscapes.jpg"
                  alt="Andreatta Waterscapes .com"
                  placeholder="tracedSVG"
                  id="andreattawaterscapes"
                  onClick={handleClick}
                  className={`${classes.projectImg} ${classes.innerImg}`}
                  style={{
                    boxShadow:
                      "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
                  }}
                />
                <div
                  className={classes.folioInfoWrapper}
                  style={{ marginTop: "-.35em" }}
                >
                  <div>
                    <Typography variant="h5" className={classes.h5}>
                      Andreatta Waterscapes
                    </Typography>
                    <Typography variant="body1" className={classes.bodyTxt}>
                      Click here to read the case study
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ position: "relative" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  src="../assets/images/jdemoblog.jpg"
                  alt="J's Blog"
                  placeholder="tracedSVG"
                  id="jsblog"
                  onClick={handleClick}
                  className={`${classes.projectImg} ${classes.jdemoblog}`}
                />
                <div
                  className={classes.folioInfoWrapper}
                  style={{ marginTop: "1.3em" }}
                >
                  <div>
                    <Typography variant="h5" className={classes.h5}>
                      J's Blog
                    </Typography>
                    <Typography variant="body1" className={classes.bodyTxt}>
                      J's Blog is a blog made by Snappy Web Design. Click here
                      to view the page
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ position: "relative" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  src="../assets/images/onesnappy.jpg"
                  alt="One Snappy"
                  id="onesnappy"
                  onClick={handleClick}
                  placeholder="tracedSVG"
                  className={`${classes.projectImg} ${classes.outerImg}`}
                />
                <div className={classes.folioInfoWrapper}>
                  <Typography variant="h5" className={classes.h5}>
                    One Snappy
                  </Typography>
                  <Typography variant="body1" className={classes.bodyTxt}>
                    One Snappy is a theme made by Snappy Web Design. Click the
                    image to view the page
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container direction="column" alignItems="center">
              <Grid
                item
                xs={12}
                className={classes.columnWrapper}
                style={{ position: "relative", cursor: "default" }}
                id="projectImage"
              >
                <StaticImage
                  tracedSVGOptions={{
                    background: primary,
                    color: "#fff",
                    blackOnWhite: false,
                  }}
                  src="../assets/images/nextup.jpg"
                  alt="Next Up Gaming"
                  id="nextup"
                  onClick={handleClick}
                  placeholder="tracedSVG"
                  style={{ height: 400, opacity: 1 }}
                  className={`${classes.projectImg} ${classes.jdemoblog}`}
                />
                <div
                  className={classes.folioInfoWrapper}
                  style={{ marginTop: "1.2em" }}
                  id="projectImage"
                >
                  <div>
                    <Typography variant="h5" className={classes.h5}>
                      NextUP Gaming
                    </Typography>
                    <Typography variant="body1" className={classes.bodyTxt}>
                      NextUP Gaming is a e-sports tournament website made by
                      Snappy Web Design.
                    </Typography>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <ContactForm />
    </>
  )
}
