import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PortfolioMain from "../components/portfolioMain"
// import PopupSurvey from "../components/PopupSurvey"

export default function PortfolioPage() {
  return (
    <Layout>
      <Seo
        title="Portfolio"
        description="Michigan Website Design portfolio for Snappy Web Design located in Ann Arbor Michigan focused on small to medium sized businesses and startups."
      />
      <PortfolioMain />
      {/* <PopupSurvey /> */}
    </Layout>
  )
}
